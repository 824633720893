import React from 'react';
import Layout from '../../../components/Layout';
import Seo from '../../../components/SEO';
import SectionIntro from '../../../components/SectionIntro';
import {getImage} from 'gatsby-plugin-image';
import {Link, useStaticQuery, graphql} from 'gatsby';
import Button from '../../../components/Button';
import iconFolders from '../../../images/product/icon-compliance-folders.svg';
import {StaticImage} from 'gatsby-plugin-image';

// Background images
import {convertToBgImage} from 'gbimage-bridge';
import BackgroundImage from 'gatsby-background-image';
import BannerRussel from '../../../components/BannerRussel';

import {TitleBordered} from '../../../components/utils';

const sectionTitleClass = `mb-6 text-3xl font-bold md:text-4xl leading-[2.75rem] md:leading-[3rem] text-blue`;
const sectionTextClass = `mb-6 text-lg text-left xl:leading-loose text-black opacity-60`;

const dataSalDefault = {
  'data-sal-duration': '700',
  'data-sal-delay': '300',
  'data-sal-easing': 'ease',
};

const ComplianceSimplified = () => {
  const dataImages = useStaticQuery(graphql`
    query {
      backgroundImage: file(relativePath: {eq: "bg/rebrand/bg-main-top.png"}) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF], quality: 100)
        }
      }
      placeholderImage02: file(relativePath: {eq: "bg/rebrand/bg-main-middle.png"}) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF], quality: 100)
        }
      }
    }
  `);
  const {backgroundImage, placeholderImage02} = dataImages;
  const image = getImage(backgroundImage);
  const bgImage = convertToBgImage(image);
  const image02 = getImage(placeholderImage02);
  const bgImage02 = convertToBgImage(image02);

  return (
    <Layout
      seo={
        <Seo
          title="Compliance Simplified | Trustle"
          description="Simplify compliance with Trustle’s advanced reporting."
        />
      }
    >
      <BackgroundImage
        Tag="section"
        // Spread bgImage into BackgroundImage:
        {...bgImage}
        preserveStackingContext
        className="bg-cover"
      >
        <section className="pt-16 pb-8">
          <div className="max-w-5xl px-5 pt-0 mx-auto text-center text-white">
            <SectionIntro
              hero="Product"
              title="Compliance Simplified"
              description="Simplify compliance with Trustle’s advanced reporting."
              isPageHeader
            />
          </div>

          <div className="mb-8 md:mb-16 text-sm w-[250px] mx-auto font-bold text-center">
            <Link to="/demo">
              <Button label="Book a Demo" />
            </Link>
          </div>
        </section>

        <section className="relative pt-16 pb-8">
          <div className="absolute right-0 hidden mb-3 xl:flex top-44 max-w-[650px] 3xl:max-w-none 4xl:hidden">
            <StaticImage src="../../../images/product/product-compliance-01b.png" alt="trustle app" className="mb-8" />
          </div>
          <div className="max-w-3xl px-5 pt-0 mx-auto mb-20 text-center text-3xl font-bold md:text-4xl leading-[2.75rem] md:leading-[3rem] text-blue">
            Complete Visibility and Reporting across Your
            <TitleBordered title="Multi-Cloud Environment. " />
          </div>
          <div className="px-5 pt-0 mx-auto mb-20 text-center text-white max-w-7xl">
            <div className="flex flex-col justify-between lg:flex-row">
              <div className="flex flex-col items-start w-full text-left xl:w-1/2 text-blue ">
                <div className="mb-4 text-xl font-bold text-left md:text-2xl">
                  Facilitate compliance, simplify reporting, and scale accountability within your entire organization.
                </div>
                <p className={sectionTextClass}>
                  Trustle makes compliance and audits effortless by unlocking visibility and access request history for
                  accurate audit reporting.
                </p>
                <p className={`${sectionTextClass} !text-blue !opacity-100 mb-4 `}>
                  <b>
                    In an ideal world, these integrated systems and applications would individually provide the
                    necessary information to satisfy audit requirements.
                  </b>
                </p>
                <p className={sectionTextClass}>
                  In reality, most organizations end up relying on spreadsheets and other costly tools that don't check
                  all the required boxes.
                </p>

                <div className="mb-4 text-xl font-bold text-left md:text-2xl">CIS, SOC2, and HIPAA Compliance.</div>
                <p className={`${sectionTextClass} text-left`}>
                  These regulations emphasize access policy governance and the enforcement of zero standing privilege
                  and least privilege access, both of which are crucial for continuous compliance.
                </p>
                <p className={`${sectionTextClass} text-left !text-blue !opacity-100`}>
                  <b>
                    Manually collecting and analyzing this data puts a strain on compliance teams who struggle to keep
                    up.
                  </b>
                </p>
                <p className={`${sectionTextClass} text-left`}>
                  This can leave organizations vulnerable to security threats that could otherwise be addressed.
                </p>
                <div className={`${sectionTitleClass} mb-20 max-w-3xl text-left`}>
                  <TitleBordered title="Enter Trustle." />
                </div>
              </div>

              <div className="mb-3 xl:hidden 4xl:block">
                <StaticImage
                  src="../../../images/product/product-compliance-01b.png"
                  alt="trustle app"
                  className="mb-8"
                />
              </div>
            </div>
          </div>
        </section>
      </BackgroundImage>

      <section className="bg-gradient-to-r from-[#009DDC] to-[#8F00FF] my-16 md:my-32">
        <div className="max-w-[1360px] relative mx-auto left-0 2xl:-left-20">
          <div className="max-w-[400px] xl:max-w-[590px] 2xl:max-w-[715px] hidden xl:flex absolute -top-10 2xl:-top-16 4xl:hidden">
            <StaticImage
              src="../../../images/product/product-features-02.png"
              alt="trustle app"
              className="w-full pb-4"
            />
          </div>
        </div>
        <div className="px-5 mb-20 lg:px-0 bg-sky-100 ">
          <div className="px-5 xl:px-0 py-12 md:py-32 mx-auto text-center text-white max-w-[1360px]">
            <div className="flex flex-col justify-between xl:flex-row-reverse 4xl:flex-row">
              <div className="max-w-[594px] text-blue xl:hidden 4xl:flex">
                <StaticImage
                  src="../../../images/product/product-features-02.png"
                  alt="trustle app"
                  className="w-full pb-4"
                />
              </div>
              <div className="flex flex-col items-start max-w-2xl text-blue">
                <p className={`mb-6 text-2xl font-bold md:text-3xl md:leading-[3rem] text-left text-white`}>
                  Trustle generates comprehensive access review reports that provide all the necessary information for
                  your auditors, making it easier to stay compliant with regulatory requirements.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section data-sal="fade" data-sal-duration="700" data-sal-delay="300" data-sal-easing="ease">
        <div className="px-5 pt-0 mx-auto mb-4 text-center text-white lg:mb-20 max-w-8xl ">
          <div className={`${sectionTitleClass} mb-20 max-w-3xl mx-auto`}>
            Connect Trustle to your environment, and you'll see the benefits in minutes
          </div>
        </div>
      </section>

      <BackgroundImage
        Tag="section"
        // Spread bgImage into BackgroundImage:
        {...bgImage02}
        preserveStackingContext
        className="bg-cover "
      >
        <section className="py-16">
          <div className="px-5 pt-0 mx-auto mb-4 text-center text-white lg:mb-20 max-w-7xl">
            <div
              className="flex flex-col items-center justify-between lg:flex-row"
              data-sal="slide-left"
              {...dataSalDefault}
            >
              <div className="max-w-[594px] pb-4 lg:pb-0">
                <StaticImage
                  src="../../../images/product/product-features-01b.png"
                  alt="trustle app"
                  className="w-full"
                  quality={100}
                />
              </div>
              <div className="flex flex-col items-start max-w-xl text-blue">
                <div className="mb-4 text-xl font-bold md:text-2xl">Automated Risk Assessment </div>

                <p className={sectionTextClass}>
                  Our automated risk assessment provides a holistic view of each Saas and Cloud environment, identifying
                  risks, misconfigurations, and vulnerabilities, and our machine learning technology detects anomalous
                  and potentially threatening access requests, in addition to well-known risks. This helps you stay one
                  step ahead of potential breaches.
                </p>
              </div>
            </div>
          </div>

          <div className="px-5 pt-0 mx-auto mb-4 text-center text-white lg:mb-20 max-w-7xl">
            <div
              className="flex flex-col-reverse items-center justify-between lg:flex-row"
              data-sal="slide-right"
              {...dataSalDefault}
            >
              <div className="flex flex-col items-start max-w-xl text-blue">
                <div className="mb-4 text-xl font-bold md:text-2xl">Uninterrupted Investigation</div>

                <p className={sectionTextClass}>
                  With Trustle, you get a deep, multi-dimensional, searchable view of all employee, contractor,
                  customer, and system identities, resources, and entitlements for each of your connected environments.
                  This empowers you to make informed decisions based on your current security posture.
                </p>
              </div>
              <div className="max-w-[594px] pb-4 lg:pb-0">
                <StaticImage
                  src="../../../images/product/product-compliance-02.png"
                  alt="trustle app"
                  className="w-full"
                />
              </div>
            </div>
          </div>

          <div className="px-5 pt-0 mx-auto mb-4 text-center text-white lg:mb-20 max-w-7xl">
            <div
              className="flex flex-col items-center justify-between lg:flex-row"
              data-sal="slide-left"
              {...dataSalDefault}
            >
              <div className="max-w-[594px] pb-4 lg:pb-0">
                <StaticImage
                  src="../../../images/product/product-compliance-03.png"
                  alt="trustle app"
                  className="w-full"
                />
              </div>
              <div className="flex flex-col items-start max-w-xl text-blue">
                <div className="mb-4 text-xl font-bold md:text-2xl">Proactive Alerting</div>

                <p className={sectionTextClass}>
                  Whether its anomalous behavior, errant usage patterns, suspicious activity, or potential threats,
                  Trustle provides proactive alerts, allowing you to take immediate action and mitigate risks before
                  they become a significant problem.
                </p>
              </div>
            </div>
          </div>

          <div className="px-5 pt-0 mx-auto mb-4 text-center text-white lg:mb-20 max-w-7xl">
            <div
              className="flex flex-col-reverse items-center justify-between lg:flex-row"
              data-sal="slide-right"
              {...dataSalDefault}
            >
              <div className="flex flex-col items-start max-w-xl text-blue">
                <div className="mb-4 text-xl font-bold md:text-2xl">Audits Made Easy</div>

                <p className={sectionTextClass}>
                  When it comes to audits, Trustle's got you covered. Trustle provides detailed reports that help you
                  stay compliant with industry regulations, saving you time and resources that would otherwise be spent
                  on manual compliance reviews.
                </p>
              </div>
              <div className="max-w-[594px] pb-4 lg:pb-0">
                <StaticImage
                  src="../../../images/product/product-compliance-04.png"
                  alt="trustle app"
                  className="w-full"
                />
              </div>
            </div>
          </div>

          <div className="px-5 pt-0 mx-auto mb-4 text-center text-white lg:mb-20 max-w-7xl">
            <div
              className="flex flex-col items-center justify-between lg:flex-row"
              data-sal="slide-left"
              {...dataSalDefault}
            >
              <div className="max-w-[594px] pb-4 lg:pb-0">
                <StaticImage
                  src="../../../images/product/product-compliance-05.png"
                  alt="trustle app"
                  className="w-full"
                />
              </div>
              <div className="flex flex-col items-start max-w-xl text-blue">
                <div className="mb-4 text-xl font-bold md:text-2xl">System Entitlement Sprawl</div>

                <p className={sectionTextClass}>
                  Trustle also helps you identify unused, underutilized, and abandoned accounts, reducing the attack
                  surface of your systems and enhancing your overall security posture.
                </p>
              </div>
            </div>
          </div>
        </section>
      </BackgroundImage>

      <section>
        <div className="bg-[#c2e5ff] bg-opacity-20 px-0 xl:px-5 mb-20 relative">
          <div className="absolute right-0 justify-end hidden pb-4 xl:max-w-auto text-blue xl:pb-0 xl:flex top-10 4xl:hidden">
            <img src={iconFolders} alt="icon folders" />
          </div>
          <div className="px-0 xl:px-5 py-12 mx-auto text-center text-white max-w-[1360px]">
            <div className="flex flex-col justify-between xl:flex-row 4xl:flex-row-reverse">
              <div className="flex justify-end pb-4 xl:max-w-auto text-blue xl:hidden xl:pb-0 4xl:flex">
                <img src={iconFolders} alt="icon folders" />
              </div>
              <div className="flex flex-col items-start max-w-5xl px-5 text-blue">
                <div className={`${sectionTitleClass}`}>Just-in-Time Access</div>
                <p
                  className={`mb-6 text-2xl text-left lg:leading-relaxed text-transparent bg-trustle-gradient bg-clip-text`}
                >
                  Trustle enforces zero standing privilege until least privileged, JIT access is required, ensuring your
                  users only have the minimal access they need, when they need it. All steps of the JIT workflow,
                  including request, approval, denial, provisioning, and revocation are logged, and made available for
                  export in PDF and CSV formats.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="pb-16">
        <BannerRussel
          title="Wondering how Trustle works?"
          description={'Get an inside look at Trustle. Schedule a demo with us today.'}
        />
      </section>
    </Layout>
  );
};
export default ComplianceSimplified;
