import React, {useState} from 'react';
import {GatsbyImage, getImage} from 'gatsby-plugin-image';
//images
import iconSlideCircle from '../images/icon-slide-circle.svg';

export const TitleBordered = ({title, align = 'center', fontSizeClass = 'text-3xl md:text-4xl'}) => {
  const sectionTitleClass = `mb-6 ${fontSizeClass}  font-bold  leading-[2.75rem] md:leading-[3rem] text-blue`;
  const alignClass = align === 'center' ? 'text-center' : align === 'left' ? 'text-left' : 'text-right';

  return (
    <>
      <div className={`${sectionTitleClass} mb-8 ${alignClass} `}>
        <span className="relative">
          <span className="relative z-20">{title}</span>
          <div className=" w-full h-[13px] bg-[#C2E5FF] bg-opacity-50 absolute -bottom-1 left-0"></div>
        </span>
      </div>
    </>
  );
};

export const UnderlineWord = ({word, align = 'left', fontSizeClass = 'text-3xl md:text-4xl'}) => {
  const sectionTitleClass = `${fontSizeClass}  font-bold  leading-[2.75rem] md:leading-[3rem] text-blue`;
  const alignClass = align === 'center' ? 'text-center' : align === 'left' ? 'text-left' : 'text-right';

  return (
    <>
      <span className={`${sectionTitleClass}  ${alignClass} `}>
        <span className="relative">
          <span className="relative z-20">{word}</span>
          <div className=" w-full h-[13px] bg-[#C2E5FF] bg-opacity-50 absolute -bottom-1 left-0"></div>
        </span>
      </span>
    </>
  );
};

export const SectionSlideCard = ({title, slideLeft = true, imagesSource = []}) => {
  const [activeImage, setActiveImage] = useState(1);

  function handleOver(index) {
    setActiveImage(index);
  }
  const activeClass = 'bg-white !text-blue transition delay-50 duration-300 ease-in-out ';

  const dataCards = [
    {
      title: 'Just-in-time access',
      description: 'Easily restore access that you’re already approved for',
      imageName: 'screenList-01b',
    },
    {
      title: 'Automated access removal',
      description: 'Based on time and sensitivity of the resource',
      imageName: 'screenList-02b',
    },
    {
      title: 'Visibility of all accounts and access',
      description: `Overall Trustle score and drill-downs into access. <br/>Recommendations, and risk scores`,
      imageName: 'screenList-03b',
    },
    {
      title: 'Audit reporting',
      description:
        'Create reports in any format of all the changes and approval chains of accesses to your cloud resources',
      imageName: 'screenList-04b',
    },
  ];
  const currentImageList = imagesSource.filter(({node}, i) => node.name === dataCards[activeImage].imageName);

  return (
    <div className="w-full py-8 ">
      <div
        className={`flex flex-col items-center max-w-6xl mx-8  mt-0 mb-0 justify-between xl:mx-auto ${
          slideLeft ? 'lg:flex-row' : 'lg:flex-row-reverse'
        }`}
      >
        <div className="flex items-start max-w-md">
          <section className="flex flex-col ">
            <div className="mb-4 text-3xl font-bold text-white">{title}</div>

            {dataCards.map((item, index) => (
              <div
                role="button"
                tabIndex={index}
                className={`border rounded-xl text-white flex items-center h-[97px] p-2 xs:p-4 mb-4 ${
                  index === activeImage && activeClass
                }`}
                key={`slideCard-${index}`}
                onMouseEnter={() => handleOver(index)}
                onMouseLeave={() => handleOver(index)}
              >
                <img src={iconSlideCircle} alt="icon" />
                <div className="pl-4">
                  <h3 className="text-xs font-bold xs:text-sm">{item.title}</h3>
                  <p className="text-xs xs:text-sm" dangerouslySetInnerHTML={{__html: item.description}}></p>
                </div>
              </div>
            ))}
          </section>
        </div>

        <div className="w-full max-w-xl lg:min-h-[600px] items-center flex">
          {currentImageList[0]?.node && (
            <GatsbyImage
              image={getImage(currentImageList[0].node)}
              width="100%"
              alt="Screenshot Trustle App"
              className="xl:max-w-[620px]"
            />
          )}
        </div>
      </div>
    </div>
  );
};
